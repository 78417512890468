/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;

    background-color: $brand-color;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-weight: 300;
    margin-bottom: 0;
    float: left;
    text-transform: uppercase;

    &,
    &:visited {
        color: $grey-color-light;
    }

    img {
	    float: left;
	    margin: $spacing-unit / 2 0;
	    margin-right: $spacing-unit / 2;
    }

    @include media-query($on-palm) {
	    padding-right: 40px;
    }
}

.site-nav {
    float: right;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .trigger {
        clear: both;
        display: none;
    }

    @include media-query($on-laptop) {
        .trigger {
            display: block;
            margin-top: -2em;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
            line-height: 2em;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

/**
* left sidebar
*/
.left.sidebar {
    width: $left-sidebar-width;
    margin-left: - $left-sidebar-width - $spacing-unit;
    float: left;
    padding-right: $spacing-unit;

    > .mainmenu {
        text-align: right;
        border-right: 1px $grey-color-light solid;
        padding-right: $spacing-unit;
        padding-bottom: $spacing-unit * 2;
	ul {
	    list-style: none;
	    font-size: 1.2em;
	    line-height: 2em;

	    li {


		    a {
			    border-right: 4px solid transparent;
			    padding-right: $spacing-unit - 4px;
			    margin-right: -$spacing-unit;
			    display: block;

			    &.active {
				    border-right-color: darken($brand-color, 15%);
			    }

			    &:hover {
				    border-right-color: $brand-color;
			    }
		    }
	    }
	}
    }

    /* hide the left sidebar on a mobile device */
    @include media-query($on-laptop) {
	    display: none;
    }
}

.wrapper .content {
	float: left;
	max-width: $content-width - 2 * $spacing-unit;

	/* no sidebars, so no need to float and the max-width is too big anyway */
	@include media-query($on-laptop) {
		float: none;
		max-width: 100%;
	}
}

/**
* right sidebar
*/
.right.sidebar {
    float: right;
    width: $right-sidebar-width;
    margin-right: - $right-sidebar-width - $spacing-unit;

    > .sponsors {
        border-left: 1px $grey-color-light solid;
        padding-left: $spacing-unit;
        padding-bottom: $spacing-unit * 2;

	ul {
	    list-style: none;
	    margin-left: 0;

	    li {
		    margin-bottom: 2 * $spacing-unit;
	    }
        }
    }

    /* hide the right sidebar on a mobile device */
    @include media-query($on-laptop) {
	    display: none;
    }
}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit / 2;
        border-bottom: solid 1px $grey-color-light;

        /* make the meta appear close to the title */
        h2 {
            margin-bottom: 0;
        }

        /* add some vertical space after the meta */
        .post-meta {
            margin-bottom: $spacing-unit / 2;
            display: block;
        }

	p {
            text-align: justify;
	}
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-title {
    letter-spacing: -1px;
    line-height: 1;
}

.post-content {
    margin-bottom: $spacing-unit;

    p {
        text-align: justify;
    }

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    table {
        margin-bottom: $spacing-unit / 2;

        th {
            text-align: left;
        }

        td {
            padding-right: $spacing-unit;
	    line-height: 1.2em;
        }
    }

    ul.gallery-list {
	    margin-left: 0;
	    margin-right: -$spacing-unit / 2;
	    list-style: none;

	    li {
		    /* two images per row */
		    width: 50%;
		    float: left;

		    /* one image using the whole width on narrow devices */
		    @include media-query($on-palm) {
			    width: 100%;
			    float: none;
		    }

		    a {
			    font-size: 1em;
			    margin-bottom: $spacing-unit / 2;
			    margin-right: $spacing-unit / 2;
			    border-radius: 8px;
			    padding: 4px;
			    border: 1px solid $grey-color-light;

			    &:hover {
				    background-color: $grey-color-light;
			    }

			    img {
				    width: 100%;
				    border-radius: 4px;
			    }
		    }
	    }
    }

}

blockquote.notes {
    float: right;
    max-width: 40%;
    margin-left: $spacing-unit / 2;
    font-size: 1em;

    p {
	    text-align: left;
    }

    @include media-query($on-palm) {
	    float: none;
	    max-width: 100%;
	    margin-left: 0;

	    p {
		    text-align: justify;
	    }
    }
}
